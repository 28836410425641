import { ACTIONS } from "../constants";

const initialState = {
  lots: [],
  lot: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  error: null,
  success: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_LOT: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_LOT_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_LOT_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        lot: payload,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_LOT_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_LOTS_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        lots: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_LOTS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        lots: results,
        isFetching: false,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_LOTS_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    default: {
      return state;
    }
  }
};
