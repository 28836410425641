


export const FETCH_OCCATIONAL_VACCINATIONS_LIST_INIT = "vaccination@FETCH_OCCATIONAL_VACCINATIONS_LIST_INIT"
export const FETCH_OCCATIONAL_VACCINATIONS_LIST_SUCCEDED = "vaccination@FETCH_OCCATIONAL_VACCINATIONS_LIST_SUCCEDED"
export const FETCH_OCCATIONAL_VACCINATIONS_LIST_FAILED = "vaccination@FETCH_OCCATIONAL_VACCINATIONS_LIST_FAILED"

export const CLEAR_VACCINATION = "vaccination@CLEAR_VACCINATION"
export const CLEAR_MEDICAL_FOLDER = "vaccination@CLEAR_MEDICAL_FOLDER"
export const CLEAR_MEDICAL_FOLDER_ERROR = "vaccination@CLEAR_MEDICAL_FOLDER_ERROR"


export const CREATE_NEW_OCCATIONAL_VACCINATIONS_INIT = "vaccination@CREATE_NEW_OCCATIONAL_VACCINATIONS_INIT"
export const CREATE_NEW_OCCATIONAL_VACCINATIONS_SUCCEDED = "vaccination@CREATE_NEW_OCCATIONAL_VACCINATIONS_SUCCEDED"
export const CREATE_NEW_OCCATIONAL_VACCINATIONS_FAILED = "vaccination@CREATE_NEW_OCCATIONAL_VACCINATIONS_FAILED"

export const CREATE_NEW_SEASON_VACCINATIONS_INIT = "vaccination@CREATE_NEW_SEASON_VACCINATIONS_INIT"
export const CREATE_NEW_SEASON_VACCINATIONS_SUCCEDED = "vaccination@CREATE_NEW_SEASON_VACCINATIONS_SUCCEDED"
export const CREATE_NEW_SEASON_VACCINATIONS_FAILED = "vaccination@CREATE_NEW_SEASON_VACCINATIONS_FAILED"



export const FETCH_SEASON_VACCINATIONS_LIST_INIT = "vaccination@FETCH_SEASON_VACCINATIONS_LIST_INIT"
export const FETCH_SEASON_VACCINATIONS_LIST_SUCCEDED = "vaccination@FETCH_SEASON_VACCINATIONS_LIST_SUCCEDED"
export const FETCH_SEASON_VACCINATIONS_LIST_FAILED = "vaccination@FETCH_SEASON_VACCINATIONS_LIST_FAILED"


export const FETCH_VACCINATION_INIT = "vaccination@FETCH_VACCINATION_INIT"
export const FETCH_VACCINATION_SUCCEDED = "vaccination@FETCH_VACCINATION_SUCCEDED"
export const FETCH_VACCINATION_FAILED = "vaccination@FETCH_VACCINATION_FAILED"

export const FETCH_OCCATIONAL_VACCINATION_INIT = "vaccination@FETCH_OCCATIONAL_VACCINATION_INIT"
export const FETCH_OCCATIONAL_VACCINATION_SUCCEDED = "vaccination@FETCH_OCCATIONAL_VACCINATION_SUCCEDED"
export const FETCH_OCCATIONAL_VACCINATION_FAILED = "vaccination@FETCH_OCCATIONAL_VACCINATION_FAILED"


export const FETCH_SEASON_VACCINATION_INIT = "vaccination@FETCH_SEASON_VACCINATION_INIT"
export const FETCH_SEASON_VACCINATION_SUCCEDED = "vaccination@FETCH_SEASON_VACCINATION_SUCCEDED"
export const FETCH_SEASON_VACCINATION_FAILED = "vaccination@FETCH_SEASON_VACCINATION_FAILED"


export const POSTPONE_VACCINATION_INIT = "vaccination@POSTPONE_VACCINATION_INIT"
export const POSTPONE_VACCINATION_SUCCEDED = "vaccination@POSTPONE_VACCINATION_SUCCEDED"
export const POSTPONE_VACCINATION_FAILED = "vaccination@POSTPONE_VACCINATION_FAILED"

export const CANCEL_VACCINATION_INIT = "vaccination@CANCEL_VACCINATION_INIT"
export const CANCEL_VACCINATION_SUCCEDED = "vaccination@CANCEL_VACCINATION_SUCCEDED"
export const CANCEL_VACCINATION_FAILED = "vaccination@CANCEL_VACCINATION_FAILED"


export const EDIT_VACCINATION_INIT = "vaccination@EDIT_VACCINATION_INIT"
export const EDIT_VACCINATION_SUCCEDED = "vaccination@EDIT_VACCINATION_SUCCEDED"
export const EDIT_VACCINATION_FAILED = "vaccination@EDIT_VACCINATION_FAILED"

export const EDIT_VACCINATION_OCCATIONAL_INIT = "vaccination@EDIT_VACCINATION_OCCATIONAL_INIT"
export const EDIT_VACCINATION_OCCATIONAL_SUCCEDED = "vaccination@EDIT_VACCINATION_OCCATIONAL_SUCCEDED"
export const EDIT_VACCINATION_OCCATIONAL_FAILED = "vaccination@EDIT_VACCINATION_OCCATIONAL_FAILED"

export const EDIT_VACCINATION_SEASON_INIT = "vaccination@EDIT_VACCINATION_SEASON_INIT"
export const EDIT_VACCINATION_SEASON_SUCCEDED = "vaccination@EDIT_VACCINATION_SEASON_SUCCEDED"
export const EDIT_VACCINATION_SEASON_FAILED = "vaccination@EDIT_VACCINATION_SEASON_FAILED"

export const FETCH_FICHE_ANTIRABIES_VACCINATION_INIT = "vaccination@FETCH_FICHE_ANTIRABIES_VACCINATION_INIT"
export const FETCH_FICHE_ANTIRABIES_VACCINATION_SUCCEDED = "vaccination@FETCH_FICHE_ANTIRABIES_VACCINATION_SUCCEDED"
export const FETCH_FICHE_ANTIRABIES_VACCINATION_FAILED = "vaccination@FETCH_FICHE_ANTIRABIES_VACCINATION_FAILED"

export const EDIT_FICHE_ANTIRABIES_VACCINATION_INIT = "vaccination@EDIT_FICHE_ANTIRABIES_VACCINATION_INIT"
export const EDIT_FICHE_ANTIRABIES_VACCINATION_SUCCEDED = "vaccination@EDIT_FICHE_ANTIRABIES_VACCINATION_SUCCEDED"
export const EDIT_FICHE_ANTIRABIES_VACCINATION_FAILED = "vaccination@EDIT_FICHE_ANTIRABIES_VACCINATION_FAILED"

export const VACCINATION_ANTIRABIQUE_DETAILS_INIT = "vaccination@VACCINATION_ANTIRABIQUE_DETAILS_INIT"
export const VACCINATION_ANTIRABIQUE_DETAILS_SUCCEDED = "vaccination@VACCINATION_ANTIRABIQUE_DETAILS_SUCCEDED"
export const VACCINATION_ANTIRABIQUE_DETAILS_FAILED = "vaccination@VACCINATION_ANTIRABIQUE_DETAILS_FAILED"

export const UPDATE_ANTIRABIES_VACCINATION_SCHEMA_INIT = "vaccination@UPDATE_ANTIRABIES_VACCINATION_SCHEMA_INIT"
export const UPDATE_ANTIRABIES_VACCINATION_SCHEMA_SUCCEDED = "vaccination@UPDATE_ANTIRABIES_VACCINATION_SCHEMA_SUCCEDED"
export const UPDATE_ANTIRABIES_VACCINATION_SCHEMA_FAILED = "vaccination@UPDATE_ANTIRABIES_VACCINATION_SCHEMA_FAILED"




export const VACCINATION_ANTIRABIQUE_LIST_INIT = "vaccination@VACCINATION_ANTIRABIQUE_LIST_INIT"
export const VACCINATION_ANTIRABIQUE_LIST_SUCCEDED = "vaccination@VACCINATION_ANTIRABIQUE_LIST_SUCCEDED"
export const VACCINATION_ANTIRABIQUE_LIST_FAILED = "vaccination@VACCINATION_ANTIRABIQUE_LIST_FAILED"


export const GET_ANTIRABIES_VACCINATION_LIST_INIT = "vaccination@GET_ANTIRABIES_VACCINATION_LIST_INIT"
export const GET_ANTIRABIES_VACCINATION_LIST_SUCCEDED = "vaccination@GET_ANTIRABIES_VACCINATION_LIST_SUCCEDED"
export const GET_ANTIRABIES_VACCINATION_LIST_FAILED = "vaccination@GET_ANTIRABIES_VACCINATION_LIST_FAILED"



export const CLEAR_ANTIRABIQUE_VACCINATION = "clear@CLEAR_ANTIRABIQUE_VACCINATION"


export const CLEAR_CHILDREN = "child@CLEAR_CHILDREN"
export const CLEAR_ANTIRABIQUE_SUCCESS = "CLEAR_ANTIRABIQUE_SUCCESS"

export const CREATE_NEW_VACCINATION_INIT = "child@CREATE_NEW_VACCINATION_INIT"
export const CREATE_NEW_VACCINATION_SUCCEDED = "child@CREATE_NEW_VACCINATION_SUCCEDED"
export const CREATE_NEW_VACCINATION_FAILED = "child@CREATE_NEW_VACCINATION_FAILED"

export const FETCH_ROUTINE_VACCINATIONS_LIST_INIT = "vaccination@FETCH_ROUTINE_VACCINATIONS_LIST_INIT"
export const FETCH_ROUTINE_VACCINATIONS_LIST_SUCCEDED = "vaccination@FETCH_ROUTINE_VACCINATIONS_LIST_SUCCEDED"
export const FETCH_ROUTINE_VACCINATIONS_LIST_FAILED = "vaccination@FETCH_ROUTINE_VACCINATIONS_LIST_FAILED"

export const CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_INIT = "child@CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_INIT"
export const CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_SUCCEDED = "child@CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_SUCCEDED"
export const CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_FAILED = "child@CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_FAILED"

export const CREATE_GROWTH_INIT = "growth@CREATE_GROWTH_INIT"
export const CREATE_GROWTH_SUCCEDED = "growth@CREATE_GROWTH_SUCCEDED"
export const CREATE_GROWTH_FAILED = "growth@CREATE_GROWTH_FAILED"

export const FETCH_GROWTH_LIST_INIT = "vaccination@FETCH_GROWTH_LIST_INIT"
export const FETCH_GROWTH_LIST_SUCCEDED = "vaccination@FETCH_GROWTH_LIST_SUCCEDED"
export const FETCH_GROWTH_LIST_FAILED = "vaccination@FETCH_GROWTH_LIST_FAILED"


export const FETCH_GROWTH_ITEM_INIT = "vaccination@FETCH_GROWTH_ITEM_INIT"
export const FETCH_GROWTH_ITEM_SUCCEDED = "vaccination@FETCH_GROWTH_ITEM_SUCCEDED"
export const FETCH_GROWTH_ITEM_FAILED = "vaccination@FETCH_GROWTH_ITEM_FAILED"



export const EDIT_GROWTH_ITEM_INIT = "growth@EDIT_GROWTH_ITEM_INIT"
export const EDIT_GROWTH_ITEM_SUCCEDED = "growth@EDIT_GROWTH_ITEM_SUCCEDED"
export const EDIT_GROWTH_ITEM_FAILED = "growth@EDIT_GROWTH_ITEM_FAILED"

export const CREATE_HEAD_CIRCUMFERENCE_INIT = "headCircumference@CREATE_HEAD_CIRCUMFERENCE_INIT"
export const CREATE_HEAD_CIRCUMFERENCE_SUCCEDED = "headCircumference@CREATE_HEAD_CIRCUMFERENCE_SUCCEDED"
export const CREATE_HEAD_CIRCUMFERENCE_FAILED = "headCircumference@CREATE_HEAD_CIRCUMFERENCE_FAILED"

export const FETCH_HEAD_CIRCUMFERENCE_LIST_INIT = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_LIST_INIT"
export const FETCH_HEAD_CIRCUMFERENCE_LIST_SUCCEDED = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_LIST_SUCCEDED"
export const FETCH_HEAD_CIRCUMFERENCE_LIST_FAILED = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_LIST_FAILED"


export const FETCH_HEAD_CIRCUMFERENCE_ITEM_INIT = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_ITEM_INIT"
export const FETCH_HEAD_CIRCUMFERENCE_ITEM_SUCCEDED = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_ITEM_SUCCEDED"
export const FETCH_HEAD_CIRCUMFERENCE_ITEM_FAILED = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_ITEM_FAILED"



export const EDIT_HEAD_CIRCUMFERENCE_ITEM_INIT = "headCircumference@EDIT_HEAD_CIRCUMFERENCE_ITEM_INIT"
export const EDIT_HEAD_CIRCUMFERENCE_ITEM_SUCCEDED = "headCircumference@EDIT_HEAD_CIRCUMFERENCE_ITEM_SUCCEDED"
export const EDIT_HEAD_CIRCUMFERENCE_ITEM_FAILED = "headCircumference@EDIT_HEAD_CIRCUMFERENCE_ITEM_FAILED"


export const COURBE_GROWTH_INIT = "headCircumference@COURBE_GROWTH_INIT"
export const COURBE_GROWTH_SUCCEDED = "headCircumference@COURBE_GROWTH_SUCCEDED"
export const COURBE_GROWTH_FAILED = "headCircumference@COURBE_GROWTH_FAILED"



export const ALLERGY_LIST_INIT = "headCircumference@ALLERGY_LIST_INIT"
export const ALLERGY_LIST_SUCCEDED = "headCircumference@ALLERGY_LIST_SUCCEDED"
export const ALLERGY_LIST_FAILED = "headCircumference@ALLERGY_LIST_FAILED"


export const ADD_ALLERGIES_INIT = "headCircumference@ADD_ALLERGIES_INIT"
export const ADD_ALLERGIES_SUCCEDED = "headCircumference@ADD_ALLERGIES_SUCCEDED"
export const ADD_ALLERGIES_FAILED = "headCircumference@ADD_ALLERGIES_FAILED"

export const EDIT_ALLERGIES_INIT = "headCircumference@EDIT_ALLERGIES_INIT"
export const EDIT_ALLERGIES_SUCCEDED = "headCircumference@EDIT_ALLERGIES_SUCCEDED"
export const EDIT_ALLERGIES_FAILED = "headCircumference@EDIT_ALLERGIES_FAILED"


export const FILTER_COURBE = "courbe@FILTER_COURBE"
export const COURBE_YEAR_MONTH = "courbe@COURBE_YEAR_MONTH"

export const BIRTH_DATE = "child@BIRTH_DATE"

export const DOWNLOAD_CALENDAR_INIT = "vaccination@DOWNLOAD_CALENDAR_INIT"
export const DOWNLOAD_CALENDAR_SUCCEDED = "vaccination@DOWNLOAD_CALENDAR_SUCCEDED"
export const DOWNLOAD_CALENDAR_FAILED = "vaccination@DOWNLOAD_CALENDAR_FAILED"

