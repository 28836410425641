import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";

import { AR, FR, EN } from "../constants";

const translationsFiles = [
  "authentication",
  "error",
  "user-management",
  "gateway",
  "organization",
  "home",
  "menu",
  "general",
  "validation",
  "childrenSpace",
  "vaccination",
  "dci",
  "referentiel",
  "profile",
  "mapi",

  "specialtie",
  "lot",
  "circonscription",
  "center",
  "director",
];

const _loadTranslation = (lang) => {
  let translations = {};

  for (let translationFile of translationsFiles) {
    const object = require(`./../locales/${lang}/${translationFile}.json`);
    translations = Object.assign({}, translations, object);
  }

  return translations;
};

const frMessages = _loadTranslation("fr");
const arMessages = _loadTranslation("ar");
const enMessages = _loadTranslation("en");
export const allMessages = {
  [AR]: arMessages,
  [FR]: frMessages,
  [EN]: enMessages,
};

export const I18nProvider = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          // console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}
      locale={locale}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};
