/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react"
import { Modal } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FlashMessages, ModalProgressBar } from "../../../../../../components/partials/controls"
//import * as actions from "../../../_redux/users/usersActions"
import { useUserGroupsUIContext } from "../../context/UserGroupsUIContext"
import { clearSuccessError, disableUserGroup, fetchUserGroups } from "../../store/actions"
import { injectIntl } from "react-intl"
import { useHistory } from "react-router-dom"

const UserGroupDisableDialog = ({ params, show, onHide, intl }) => {
  const history = useHistory();
  const isDisable = history.location.pathname.includes('disable')
  // Users UI Context
  const userGroupUIContext = useUserGroupsUIContext()
  const usersGroupUIProps = useMemo(() => {
    return {
      setIds: userGroupUIContext.setIds,
      queryParams: userGroupUIContext.queryParams,
    }
  }, [userGroupUIContext])
  // Users Redux state
  const dispatch = useDispatch()
  const { isLoading, success } = useSelector(
    (state) => ({
      isLoading: state.admin.userGroup.isLoading,
      success: state.admin.userGroup.success
    }),
    shallowEqual
  )

  // if !id we should close modal
  useEffect(() => {
    if (success && show) {
      onHide()
      dispatch(fetchUserGroups(usersGroupUIProps.queryParams))
      usersGroupUIProps.setIds([])

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, show])

  const onDisableUserGroup = () => {
    // server request for deleting smsSkeleton by id
    dispatch(disableUserGroup(params))
  }
  return (
    <>
      {success && isDisable && <FlashMessages successMsg={[
        { condition: success, label: intl.formatMessage({ id: "ROLE_DISABLE_SUCCESS" }) },
      ]} onClose={clearSuccessError} />}
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="USER_GROUP.DISABLE.TITLE" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="USER_GROUP.DISABLE.MSG" />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              <FormattedMessage id="GENERAL.CANCEL" />
            </button>
            <> </>
            <button
              type="button"
              disabled={isLoading}
              onClick={onDisableUserGroup}
              className="btn btn-danger btn-elevate"
            >
              {isLoading && <span className="px-5 spinner spinner-white"></span>}
              <FormattedMessage id="GENERAL.DISABLE" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>

  )
}


export default injectIntl(UserGroupDisableDialog)
