import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import ClearIcon from "@material-ui/icons/Clear"
import { FieldError } from "./FieldError"
import { FormattedMessage } from "react-intl"
import { clearChildren } from "src/modules/admin/containers/childrenSpace/store/actions"
import { useDispatch } from "react-redux"
import {isFunction} from "lodash"

const useStyles = makeStyles({
  root: {
    padding: ".5rem .875rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "2.5rem",
    backgroundColor:"#FAFAFA",
    backgroundClip: "border-box",
    border: ".0625rem solid #D6D6D6",
    borderRadius: ".25rem 0rem 0rem .25rem",
    boxShadow: "0rem 0rem 1.875rem 0rem rgba(82, 63, 105, 0.05) !important"
  },
  input: {
    padding: ".3125rem",
    height: "2.5rem",
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
})
const buttonStyle={
  display: "flex",
  color:"white",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: ".5rem .625rem",
  elevation: "0",
  outline: 'none',
  gap: ".5rem",
  width: "11.25rem",
  height: "2.5rem",
  background: "#DC3545",
  borderRadius: ".25rem" 
  }
const InputAddons = ({
  field, // { name, value, onChange, onBlur }
  label,
  inputGroupClassName = "form-group",
  inputClassName = "form-control",
  withFeedbackLabel = true,
  customFeedbackLabel,
  buttonText,
  isLoading,
  form,
  maxLength,
  pattern,
  onlyNumber,
  type = "text",
  dispatchedAction,
  ...props
}) =>  {
  const classes = useStyles()
const [value,setValue] = useState();
 const dispatch = useDispatch();
  const onClearForm = () => {
   
    if (isFunction(dispatchedAction)){
      dispatch(dispatchedAction())
    }
    setValue("");
    if (!isFunction(dispatchedAction)){
      form.resetForm()
      form.submitForm()
    }
     
  }

  return (
    <div style={{
      display: 'flex',
flexDirection: 'column',
alignItems: 'flex-start',
padding: '0rem',
gap: '.375rem',
height: '4.375rem'
    }}>
      <div style={{
           display: 'flex',
           flexDirection: 'row',
           alignItems: 'flex-start',
           padding: '0rem',
           gap: '.125rem',
           height: '1.5rem'
      }}>
        <label style={{
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight:' 500',
          fontSize: '1.125rem',
          lineHeight: '1.75rem',
          color: '#737373'
        }}>{label}</label>
      </div>
    <div style={{display:'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap : ".75rem",
    padding: '0rem',
    width: '100%'}}>
            <Paper className={classes.root}>
        <InputBase
          onChange={(e)=>{
            onlyNumber ?  setValue(e.target.value.replace(/[^0-9]/g, '')) :
            setValue(e.target.value)
        }}
          className={classes.input}
          autoComplete="off"
          inputProps={ { ...props, ...field,maxLength,pattern,value} }
          type={type}
        />
        <IconButton type="button" onClick={onClearForm} className={classes.iconButton} aria-label="Search">
          <ClearIcon className="text-danger" />
        </IconButton>
      </Paper>
      <button type="submit" className="btn" style={buttonStyle}>                            
      <FormattedMessage id = "GENERAL.SEARCH" />
  </button>
      <FieldError fieldName={field.name} />
    </div>
    </div>
  )
}


export default InputAddons
