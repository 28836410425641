import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
import { lazy } from "react";
import { LIST, MODULES_PERMISSIONS, VIEW } from "src/constants";
import { VaccinationManagement } from "src/constants/ModulesPermissions";
import { LOT } from "src/constants/AppPermissions";

const LotComponent = lazy(() => import("./../components/card/LotPage"));
const LotShow = lazy(() => import("./../components/card/LotShow"));

// const { LOT } = MODULES_PERMISSIONS;

export const lotList = {
  path: "/repositories/lot",
  component: LotComponent,
  can: VaccinationManagement.module[LOT].permissions[LIST],
};

// export const lotShow = {
//   path: "/show/:param/lot",
//   component: LotShow,
//   can: LOT.permissions[VIEW]
// }

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    lotList,
    // lotShow,
  }
);
