import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader } from "..";
import { ControlUIProvider } from "../store";
import { CircularProgress } from "@mui/material";
import { isRLTLang } from "src/i18n";
import classNames from 'classnames';

const ShowCardViewDB = React.memo(({ title, avatar, value1, value2, total, isLoading, className = "" }) => {
  const cardHeaderClass = classNames({
    'd-flex align-items-center justify-content-center': !avatar,
    'p-0 m-0 pt-1': avatar,
    'pr-1': avatar && isRLTLang(),
    'pl-1': avatar && !isRLTLang(),
  });

  const contentStyle = {
    fontWeight: "bold",
    color: "gray",
    fontSize: "1.25rem",
    lineHeight: "5.5rem",
    paddingInline: "2rem",
    paddingBottom: "1rem",
    textAlign: "center",
  };

  const valueContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const messageStyle = {
    color: "black",
    fontSize: "1rem",
    fontWeight: "700",
    fontFamily: "Open Sans",
    fontStyle: "normal",
  };

  const valueStyle = {
    fontSize: "5.5rem",
    fontWeight: "bold",
    color: "red",
  };

  return (
    <ControlUIProvider>
      <Card className={className}>
        {/* <CardHeader
          className={cardHeaderClass}
          title={!avatar && title}
          icon={avatar && avatar}
        /> */}
        <CardBody>
          <div style={contentStyle} className="d-flex flex-column align-items-center w-100 h-100">
            {isLoading ? (
              <CircularProgress
                size={88}
                thickness={5}
                disableShrink
                color="error"
              />
            ) : (
              <>
                <div style={messageStyle}><FormattedMessage id="HOME.INSCRIPTION_COUNT" /></div>

                <div style={valueStyle}>{total}</div>
                <hr style={{ width: "100%", margin: "1rem 0" }} />
                <div style={valueContainerStyle}>
                <div style={messageStyle}><FormattedMessage id="HOME.INSCRIPTION_AGENT_COUNT" /></div>

                  <div style={valueStyle}>{value1}</div>
                </div>
                <hr style={{ width: "100%", margin: "1rem 0" }} />
                <div style={valueContainerStyle}>
                <div style={messageStyle}><FormattedMessage id="HOME.INSCRIPTION_CITOYEN_COUNT" /></div>

                  <div style={valueStyle}>{value2}</div>
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </ControlUIProvider>
  );
});

export default ShowCardViewDB;
