import {
  ALLERGY,
  CHILDREN,
  CITIZEN_MANAGEMENT,
  DASHBOARD,
  GROWTH_METRICS,
  HEAD_CIRCUMFERENCE_METRICS,
  MAPI,
  MEDICAL_FOLDER,
  MEDICAL_FOLDER_MANAGEMNT,
  VACCINATION,
  VACCINATION_MANAGEMENT,
  CIRCONSCRIPTION,
  CENTER,
  DCI,
  DIRECTOR,
  REFERENTIEL,
  LISTS_MANAGEMENT,
  USER_MANAGEMENT,
  USERS,
  SPECIALTIE,
  GROUPS,
  LOT,
  SUBSCRIPTION,
  OCCASIONAL,
  SEASONAL,
  ANTIRABIQUE,
  ROUTINE,
} from "./AppPermissions";
import {
  CREATE,
  UPDATE,
  VIEW,
  DELETE /*, UNDELETE,VIEW_UPDATE, ACTIVATE, DEACTIVATE,*/,
  SEARCH,
  REGISTRED,
  LIST,
  VIEW_MENU,
  CLOSE,
  ACTIVATE,
  DEACTIVATE,
  ACCEPT,
  REJECT,
  UNDELETE,
  UPDATE_PAST,
} from "./Permissions";

// Evax-ii-Middle	citizen_management	can_check_parent_existence

// Evax-ii-Middle	medical_folder_management	can_view_allergie_details

export const CitizenManagement = {
  module: {
    [CHILDREN]: {
      permissions: {
        [CREATE]: `${CITIZEN_MANAGEMENT}.can_add_children`,
        [SEARCH]: `${CITIZEN_MANAGEMENT}.can_search_children`,
        [REGISTRED]: `${CITIZEN_MANAGEMENT}.can_registred_children`,
        [CLOSE]: `${CITIZEN_MANAGEMENT}.can_close_medical_folder`,
      },
    },
    [SUBSCRIPTION]: {
      permissions: {
        [ACCEPT]: `${CITIZEN_MANAGEMENT}.can_validate_subscription_request`,
        [REJECT]: `${CITIZEN_MANAGEMENT}.can_validate_subscription_request`,
        [SEARCH]: `${CITIZEN_MANAGEMENT}.can_view_subscription_request_list`,
        [VIEW]: `${CITIZEN_MANAGEMENT}.can_view_subscription_request_list`,
        [UPDATE]: `${CITIZEN_MANAGEMENT}.can_edit_citizev`,

      },
    },
    [DASHBOARD]: {
      permissions: {
        [VIEW]: `${CITIZEN_MANAGEMENT}.can_view_dashboard`,
        // [LIST]: {
        //   governorate: `${CITIZEN_MANAGEMENT}.can_view_governorate_dashboard`,
        //   circonscription: `${CITIZEN_MANAGEMENT}.can_view_circonscription_dashboard`,
        //   center: `${CITIZEN_MANAGEMENT}.can_view_center_dashboard`,
        // },
      },
    },
  },
};

export const VaccinationManagement = {
  module: {
    [VACCINATION]: {
      permissions: {
        [CREATE]: {
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_add_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_add_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_add_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_add_vaccination_${ANTIRABIQUE}`,

        },
        [DELETE]: {
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_delete_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_delete_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_delete_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_delete_vaccination_${ANTIRABIQUE}`,

        },
        [UPDATE]:{
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_edit_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_edit_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_edit_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_edit_vaccination_${ANTIRABIQUE}`,

        },
        [UPDATE_PAST]: `${VACCINATION_MANAGEMENT}.can_edit_past_vaccination`,
        [VIEW]: {
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_view_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_view_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_view_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_view_vaccination_${ANTIRABIQUE}`,

        },
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
      },
    },
    [DCI]: {
      permissions: {
        [CREATE]: `${VACCINATION_MANAGEMENT}.can_add_dci`,
        [DELETE]: `${VACCINATION_MANAGEMENT}.can_delete_dci`,
        [UPDATE]: `${VACCINATION_MANAGEMENT}.can_edit_dci`,
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_dci_list`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_dci_list`,
        [VIEW_MENU]: `${VACCINATION_MANAGEMENT}.can_view_dci_menu`,
      },
    },
    [CIRCONSCRIPTION]: {
      permissions: {
        [CREATE]: `${VACCINATION_MANAGEMENT}.can_add_vaccination`,
        [DELETE]: `${VACCINATION_MANAGEMENT}.can_delete_vaccination`,
        [UPDATE]: `${VACCINATION_MANAGEMENT}.can_edit_vaccination`,
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_vaccination`,
        // [CREATE]: `${VACCINATION_MANAGEMENT}.can_add_dci`,
        // [DELETE]: `${VACCINATION_MANAGEMENT}.can_delete_dci`,
        // [UPDATE]: `${VACCINATION_MANAGEMENT}.can_edit_dci`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
      },
    },
    [CENTER]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_center`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
      },
    },
    [DIRECTOR]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_director`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
        [VIEW_MENU]: `${VACCINATION_MANAGEMENT}.can_view_specialite_menu`,

      },
    },
    [DASHBOARD]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_dashboard`,
        [LIST]: {
          governorate: `${VACCINATION_MANAGEMENT}.can_view_governorate_dashboard`,
          circonscription: `${VACCINATION_MANAGEMENT}.can_view_circonscription_dashboard`,
          center: `${VACCINATION_MANAGEMENT}.can_view_center_dashboard`,
        },
      },
    },

    [SPECIALTIE]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_specialite`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
      },
    },

    [LOT]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_lot`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
      },
    },
  },
};

export const MedicalFolderManagement = {
  module: {
    [ALLERGY]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_allergies`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_allergies`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_allergies`,
        //[DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_allergies`,
      },
    },
    [GROWTH_METRICS]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_growth_metrics`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_growth_metrics`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_growth_metrics`,
        //[DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_growth_metrics`,
      },
    },
    [HEAD_CIRCUMFERENCE_METRICS]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_head_circumference_metrics`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_head_circumference_metrics`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_head_circumference_metrics`,

        // [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_head_circumference_metrics`,
      },
    },
    [MEDICAL_FOLDER]: {
      permissions: {
        // [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_medical_folder`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_medical_folder`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_medical_folder`,

        //  [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_medical_folder`,
      },
    },
    [MAPI]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_mapi`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_mapi`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_mapi`,

        // [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_mapi`,
      },
    },
  },
};

export const ListsManagement = {
  module: {
    [REFERENTIEL]: {
      permissions: {
        // [CREATE]: `${LISTS_MANAGEMENT}.can_add_lists`,
        [VIEW]: `${LISTS_MANAGEMENT}.can_view_lists`,
        [UPDATE]: `${LISTS_MANAGEMENT}.can_edit_lists`,
        [LIST]: `${LISTS_MANAGEMENT}.can_view_all_lists`,
        // [SEARCH]: `${LISTS_MANAGEMENT}.can_search_lists`,
        // [DEACTIVATE]: `${LISTS_MANAGEMENT}.can_delete_lists`,
      },
    },
  },
};

export const UsersManagement = {
  module: {
    [USERS]: {
      permissions: {
        [CREATE]: `${USER_MANAGEMENT}.can_add_users`,
        [VIEW]: `${USER_MANAGEMENT}.can_view_users`,
        [UPDATE]: `${USER_MANAGEMENT}.can_edit_users`,
        // [SEARCH]: `${USER_MANAGEMENT}.can_search_lists`,
        [DEACTIVATE]: `${USER_MANAGEMENT}.can_delete_users`,
        [ACTIVATE]: `${USER_MANAGEMENT}.can_edit_users`,
        // [DELETE]: `${USER_MANAGEMENT}.can_delete_users`,
        // [UNDELETE]: `${USER_MANAGEMENT}.can_edit_users`,
      },
    },
    [GROUPS]: {
      permissions: {
        [CREATE]: `${USER_MANAGEMENT}.can_add_groups`,
        [VIEW]: `${USER_MANAGEMENT}.can_view_groups`,
        [UPDATE]: `${USER_MANAGEMENT}.can_edit_groups`,
        //[SEARCH]: `${USER_MANAGEMENT}.can_search_groups`,
        [DEACTIVATE]: `${USER_MANAGEMENT}.can_deactivate_groups`,
        [ACTIVATE]: `${USER_MANAGEMENT}.can_activate_groups`,
        [LIST]: `${USER_MANAGEMENT}.can_view_list_groups`,
      },
    },
  },
};

// export const SPECIALTIE = {
//   module: "Specialtie",
//   permissions: {
//     [VIEW]: "view_specialtie",
//     [CREATE]: "add_specialtie",
//     [UPDATE]: "change_specialtie",
//     [ACTIVATE]: "activate_specialtie",
//     [DEACTIVATE]: "delete_specialtie",
//   },
// };
