import UserGroupDeleteDialog from "./../components/dialog/UserGroupDeleteDialog"
import UserGroupEnableDialog from "./../components/dialog/UserGroupEnableDialog"
import UserGroupDisableDialog from "./../components/dialog/UserGroupDisableDialog"
import {MODULES_PERMISSIONS, DEACTIVATE,DELETE, ACTIVATE} from "src/constants"
import {pages} from "./index"
import { combinePathRoutes } from "src/helpers"
import { GROUPS } from "src/constants/AppPermissions"
import { UsersManagement } from "src/constants/ModulesPermissions"

const {USER_GROUP} = MODULES_PERMISSIONS


export const userGroupDelete = {
  path: "/delete/userGroup/:param",
  component: UserGroupDeleteDialog,
  can: UsersManagement.module[GROUPS].permissions[DELETE],
}

// export const userGroupsDelete = {
//   path: "/delete/userGroups",
//   component: UserGroupsDeleteDialog,
//   // can: USER_GROUP.permissions[DEACTIVATE]
// }
export const userGroupDisable = {
  path: "/user-groups/:param/disable",
  component: UserGroupDisableDialog,
  can: UsersManagement.module[GROUPS].permissions[DEACTIVATE],
}
export const userGroupEnable = {
  path: "/user-groups/:param/enable",
  component: UserGroupEnableDialog,
  can: UsersManagement.module[GROUPS].permissions[ACTIVATE],
}
const path = pages.userGroupList.path

export default combinePathRoutes({ path }, {
  userGroupDelete,
 // userGroupsDelete,
  userGroupDisable,
  userGroupEnable
})
