import routes from "src/routes"

import { combinePathRoutes } from "./../../../../../helpers"
import { lazy } from "react"
import { CREATE, UPDATE, VIEW } from "src/constants"
import { UsersManagement } from "src/constants/ModulesPermissions"
import { USERS } from "src/constants/AppPermissions"
const User = lazy(()=>import("./../User"))
const UserEdit = lazy(() => import("./../UserEdit"))
const UserShow = lazy(() => import("./../UserShow"))
const UserPage = lazy(() => import("./../UserPage"))

// const { USER } = MODULES_PERMISSIONS

export const userCreate = {
  path: "/users/new",
  component: User,
  exact: true,
  // can: USER.permissions[CREATE],
  can: UsersManagement.module[USERS].permissions[CREATE],

}

export const userEdit = {
  path: "/users/:param/edit",
  component: UserEdit,
  // can: USER.permissions[UPDATE],
  can: UsersManagement.module[USERS].permissions[UPDATE],

}

export const userShow = {
  path: "/users/:param/show",
  component: UserShow,
  // can: USER.permissions[VIEW],
  can: UsersManagement.module[USERS].permissions[VIEW],

}

export const userList = {
  path: "/users",
  component: UserPage,
  // can: USER.permissions[VIEW],
  can: UsersManagement.module[USERS].permissions[VIEW],

}
const path = routes.admin.path

export default combinePathRoutes(
  {
    path
  },
  {
    userCreate,
    userEdit,
    userShow,
    userList,

  }
)
