export const DCIS = "/api/ALL_DCI"
export const DCI_CREATE = "/api/ADD_DCI"
export const DCI = "/api/ALL_DCI/:param"
export const DCI_ACTIVATE = "/api/DCI/:param/activate"
export const DCI_DEACTIVATE = "/api/DCI/:param/deactivate"
export const DCI_DELETE = "/api/dci/:param/delete"

export const EXPORT_DCI = "/api/dcis/export"
export const IMPORT_DCI = "/api/dci/import"

export const SPECIALITY_DCI = "/api/specialityList"
export const FILTER_SPECIALITY_DCI = "/api/specialityList"
