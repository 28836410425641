import { ACTIONS } from "../constants";

const initialState = {
  centers: [],
  center: [],
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_CENTER: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_CENTERS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_CENTERS_SUCCEDED: {
      console.log(payload);
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        centers: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_CENTERS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_CENTER_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        dci: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_CENTER_SUCCEDED: {
      return { ...state, center: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_CENTER_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    default: {
      return state;
    }
  }
};
