import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
import { lazy } from "react";
import { CREATE, LIST, UPDATE, VIEW } from "src/constants";
import { CENTER } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";

const centerComponent = lazy(() => import("./../components/card/center"));

export const centerList = {
  path: "/structure/center",
  component: centerComponent,
  can: VaccinationManagement.module[CENTER].permissions[LIST],
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    centerList,
  }
);
