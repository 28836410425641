
import {pages as CitoyenRoutes} from './containers/citoyen/routes'

import { pages as homeRoutes } from "./containers/home/routes";
import { pages as profileRoutes } from "./containers/profile/routes";
import { pages as childrenRoutes } from "./containers/childrenSpace/routes";
import { pages as vaccinationRoutes } from "./containers/vaccination/routes";
import { pages as specialtiesRoutes } from "./containers/specialties/routes";
import { pages as mapiRoutes } from "./containers/mapi/routes";
import { pages as listRoutes } from "./containers/list/routes";
import { pages as DCIRoutes } from "./containers/dci/routes";
import { pages as CirconscriptionRoutes } from "./containers/circonscription/routes";
import { pages as CenterRoutes } from "./containers/vaccinationCenter/routes";
import { pages as DirectorRoutes } from "./containers/generalDirect/routes";
import { pages as userRoutes } from "./containers/user/routes";
import { pages as userGroupRoutes } from "./containers/user-group/routes";
import { pages as lotsRoutes } from "./containers/lots/routes";

// Combined routes
export default {
  ...homeRoutes,
  ...profileRoutes,
  ...childrenRoutes,
  ...vaccinationRoutes,
  ...mapiRoutes,
  ...listRoutes,
  ...specialtiesRoutes,
  ...DCIRoutes,
  ...lotsRoutes,
  ...CirconscriptionRoutes,
  ...CenterRoutes,
  ...DirectorRoutes,
  ...userRoutes,
  ...userGroupRoutes,
  ...CitoyenRoutes,
};
  

