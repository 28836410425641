import { ACTIONS } from "../constants"


const initialState = { 
  dcis : [],
  dci : [],
  specialitys : [],
  speciality : [],
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted :false,
  },
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_DCI: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_DCIS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_DCIS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, dcis: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_DCIS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_SPECIALITY_DCI_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_SPECIALITY_DCI_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, specialitys: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_SPECIALITY_DCI_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_FILTER_SPECIALITY_DCI_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_FILTER_SPECIALITY_DCI_SUCCEDED : {
      return { ...state, specialitys: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FILTER_SPECIALITY_DCI_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_DCI_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_DCI_SUCCEDED : {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_DCI_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_DCI_INIT: {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_DCI_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_DCI_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }
 
    case ACTIONS.FETCH_DCI_INIT : {
      return { ...state, isLoading: true, success: initialState.success, dci: null, error: null }
    }
    case ACTIONS.FETCH_DCI_SUCCEDED : {
      return { ...state, dci: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_DCI_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.DEACTIVATE_DCI_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DEACTIVATE_DCI_SUCCEDED : {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DEACTIVATE_DCI_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.ACTIVATE_DCI_INIT : { 
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ACTIVATE_DCI_SUCCEDED : {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ACTIVATE_DCI_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.DELETE_DCI_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_DCI_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_DCI_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }
    default: {
      return state
    }
  }
}
