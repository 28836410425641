import { lazy } from "react"
import {UPDATE, MODULES_PERMISSIONS, VIEW, CREATE,LIST} from "src/constants"
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"
import { GROUPS } from "src/constants/AppPermissions"
import { UsersManagement } from "src/constants/ModulesPermissions"

const Group = lazy(() => import("../UserGroupNewEdit"))
const GroupEdit = lazy(() => import("../UserGroupNewEdit"))
const GroupShow = lazy(() => import("../UserGroupShow"))
const GroupPage = lazy(() => import("../UserGroupPage"))

const { USER_GROUP } = MODULES_PERMISSIONS

export const userGroupCreate = {
  path: "/user-groups/new",
  component: Group,
  can: UsersManagement.module[GROUPS].permissions[CREATE],
  exact: true,
}

export const userGroupEdit = {
  path: "/user-groups/:param/edit",
  component: GroupEdit,
  can: UsersManagement.module[GROUPS].permissions[UPDATE],
}

export const userGroupShow = {
  path: "/user-groups/:param/show",
  component: GroupShow,
  can: UsersManagement.module[GROUPS].permissions[VIEW],
}

export const userGroupList = {
  path: "/user-groups",
  component: GroupPage,
  can: UsersManagement.module[GROUPS].permissions[LIST],
}

const path = routes.admin.path

export default combinePathRoutes({ path }, {
  userGroupCreate,
  userGroupShow,
  userGroupEdit,
  userGroupList, 
})
