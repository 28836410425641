import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import Datetime from "react-datetime";
import moment from "moment";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import SVG from "react-inlinesvg";
import "moment/locale/ar-tn";
import "moment/locale/fr";
import { getLang, isRLTLang } from "./../../../../../i18n";
import { FR, AR } from "./../../../../../constants";
import "react-datetime/css/react-datetime.css";
import useHideAndShowField from "./hooks/useHideAndShowField";
import { getAttr, toAbsoluteUrl } from "src/helpers";
import useResizeHook from "./hooks/useResizeHook";
import { useIntl } from "react-intl";
import { FormattedError } from "../../alerts/FormattedError";

const locale = {
  [FR]: "fr",
  [AR]: "ar-tn",
};

const DatePickerFieldWithInput = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  relatedTo,
  changeDateValue,
  icon = false,
  isValidDate = () => true,
  errorValidation = "",
  inputGroupClassName = "form-group",
  inputClassName = "form-control-date-picker form-control",
  type = "date",
  format = "DD/MM/YYYY",
  placeholder,
  size = 12,
  dateFormat = true,
  timeFormat = false,
  required = false,
  disabled = false,
  maxLength = 256,
  initialValue = null,
  initialValueChainedField = "",
  resetConditionValue = undefined,
  mapi=false,
  ...props
}) => {
  const { touched, errors } = form;
  const { value } = field;
  const [currentDate, setCurrentDate] = useState(undefined);
  const [manualInputValue, setManualInputValue] = useState("");
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name);
  const [toggle, setToggle] = useState(false);
  const intl = useIntl();
  const iconStyle = isRLTLang()
    ? {
        position: "absolute",
        height: "1.125rem",
        minWidth: "1.25rem",
        top: "calc(100% - 1.875rem)",
        right: "calc(100% - 2.5rem)",
      }
    : {
        position: "absolute",
        height: "1.125rem",
        minWidth: "1.25rem",
        top: "calc(100% - 1.875rem)",
        left: "calc(100% - 2.5rem)",
      };

  const Input = ({setChangeFromInput,isValid, setIsValid, manualInputProps, setManualInputProps, openCalendar, closeCalendar, ...props }) => {
    const [manualInputValue, setManualInputValue] = useState("");
    
    const toggleCal = () => {
      if (!disabled) {
        setToggle((prev) => !prev);
        if (!toggle) openCalendar();
        else closeCalendar();
      }
    };

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      const filteredValue = inputValue.replace(/[^0-9/]/g, ''); // Filter out non-numeric and non-slash characters
      console.log("handleInputChange",filteredValue)
      setManualInputValue(filteredValue);
      setChangeFromInput(true);
      if(filteredValue == ""){
        setManualInputProps("");
        setCurrentDate(undefined);
        form.setFieldValue(field.name, "");
      }
    };

    const handleInputBlur = (e) => {
      const date = moment(manualInputValue, format, true);
      console.log("handleInputBlur",isValidDate(date),errorValidation)
      if (!isValidDate(date)) {
        setManualInputProps("");
        form.setFieldValue(field.name, "");
        setIsValid(false)
        setCustomError(changeFromInput ? errorValidation : null);

      }else if (date.isValid()) {
        setManualInputProps(manualInputValue);
        setCurrentDate(date);
        form.setFieldValue(field.name, date.format(format));
        setIsValid(true)
        setCustomError(null);

      } else {
        setManualInputProps("");
        form.setFieldValue(field.name, "");
        setIsValid(false)
        setCustomError(changeFromInput ? intl.formatMessage({ id: "Invalid date format. Please use DD/MM/YYYY." }) : null);

      }
    };


    return (
      <div style={{ width: "100%" }}>
        <SVG
          src={toAbsoluteUrl("/media/svg/dateIcon.svg")}
          style={iconStyle}
          onClick={toggleCal}
          size="3em"
        />
        <input
          {...props}
          value={manualInputValue || manualInputProps}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          style={{
            width: "100%",
            padding: ".625rem",
            textAlign: "start",
            backgroundColor: "#FAFAFA",
            height: 40,
            borderColor: isValid ? '' : 'red',
          }}
          placeholder= {isRLTLang() ? "سنة/شهر/يوم" : "JJ/MM/AAAA"}
        />
      </div>
    );
  };

  const [width, height] = useResizeHook();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [changeFromInput, setChangeFromInput] = useState(false);
  const [customError, setCustomError] = useState(null);

  const formik = useFormikContext();
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });

  useEffect(() => {
    adjustDatePickerPosition();
  }, [height, width]);

  useEffect(() => {
    if (mapi) {
      if (value) {
        const date = moment(value, format);
        if (date.isValid()) {
          setCurrentDate(date);
          setManualInputValue(date.format(format));
        } else {
          setManualInputValue(value);
        }
      } else {
        // Set initial value when field is empty
        setManualInputValue(initialValue);
        setCurrentDate(undefined);
      }
    }
    else{
      let date;
      if (!value) return;
      if (_.isString(value) && !_.isEmpty(value)) {
        date = moment(value, format);
      }
      if (_.isDate(value)) {
        date = moment(value);
      }
      if (date) {
        setCurrentDate(date);
        setManualInputValue(date.format(format));
        form.setFieldValue(field.name, date.format(format));
        if (relatedTo && changeDateValue) form.setFieldValue(relatedTo, changeDateValue(moment(date)));
      } else {
        setCurrentDate(undefined);
        setManualInputValue(value);
        form.setFieldValue(field.name, initialValue);
      }
  
    } 

  }, [mapi, value, format, initialValue]);

  useEffect(() => {
    if (!_.isEmpty(initialValueChainedField) && resetConditionValue) {
      if (getAttr(formik.values, initialValueChainedField, undefined) === resetConditionValue) {
        form.setFieldValue(field.name, "");
        formik.setFieldValue(field.name, "");
        setCurrentDate(undefined);
        setManualInputValue("");
        setIsDisabled(false);
      } else {
        form.setFieldValue(field.name, initialValue);
        formik.setFieldValue(field.name, initialValue);
        setIsDisabled(true);
        setCustomError(null);
        setIsValid(true)
      }
    }
  }, [getAttr(formik.values, initialValueChainedField)]);

  const dir = isRLTLang() ? "rtl" : "ltr";

  function adjustDatePickerPosition() {
    let elements = [...document.getElementsByClassName("rdt")];
    if (_.isArray(elements)) {
      elements.forEach((element) => {
        const elementRect = element.getBoundingClientRect();
        const spaceBelow = window.innerHeight - elementRect.bottom;
        let popElements = [...document.getElementsByClassName("rdtPicker")];
        if (_.isArray(popElements)) {
          popElements.forEach((popElement) => {
            if (spaceBelow < 250) {
              popElement.classList.add("flip-Position");
            } else {
              popElement.classList.remove("flip-Position");
            }
          });
        }
      });
    }
  }

  return (
    <div dir={dir} className={`${inputGroupClassName} ${conditionalShowAndHideClassName} col-lg-${size}`}>
      {label && (
        <label className="custom-label-style">
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <Datetime
        onOpen={adjustDatePickerPosition}
        popperPlacement="top-start"
        isValidDate={isValidDate}
        renderInput={(props, openCalendar, closeCalendar) =>
          icon ? <Input {...props} setChangeFromInput={setChangeFromInput} isValid={isValid} setIsValid={setIsValid} manualInputProps={manualInputValue} setManualInputProps={setManualInputValue} openCalendar={openCalendar} closeCalendar={closeCalendar} /> : null
        }
        key={field.name}
        initialValue={currentDate && currentDate.isValid() ? currentDate.toDate() : undefined}
        dateFormat={dateFormat && format}
        timeFormat={false}
        closeOnSelect={true}
        inputProps={{
          key: field.name,
          readOnly: false,
          disabled: disabled || isDisabled,
          autoComplete: "off",
          className: `${inputClassName} ${fieldCSSClasses} ${(disabled || isDisabled) && `disabled`}`,
          name: field.name,
          value: manualInputValue,
        }}
        locale={locale[getLang()]}
        input={true}
        onChange={(date) => {
          const formattedDate = moment(date).format(format);
          setCurrentDate(date);
          setManualInputValue(formattedDate);
          setChangeFromInput(false);
          form.setFieldValue(field.name, formattedDate);
          if (date.isValid()) {
            setIsValid(true)
            setCustomError(null);
          } else {
            setIsValid(false)
            setCustomError(!changeFromInput ? intl.formatMessage({ id: "Invalid date format. Please use DD/MM/YYYY." }) : null);

          }
        }}
        {...props}
      />
      {customError ? <FormattedError message={customError} onClose={null} /> 
                   : <FieldError fieldName={field.name} />}
      
    </div>
  );
};

export default DatePickerFieldWithInput;
