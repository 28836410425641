export const FETCH_CIRCONSCRIPTION_INIT = "circonscription@FETCH_CIRCONSCRIPTION_INIT"
export const FETCH_CIRCONSCRIPTION_SUCCEDED = "circonscription@FETCH_CIRCONSCRIPTION_SUCCEDED"
export const FETCH_CIRCONSCRIPTION_FAILED = "circonscription@FETCH_CIRCONSCRIPTION_FAILED"


export const FETCH_CIRCONSCRIPTIONS_INIT = "circonscription@FETCH_CIRCONSCRIPTIONS_INIT"
export const FETCH_CIRCONSCRIPTIONS_SUCCEDED = "circonscription@FETCH_CIRCONSCRIPTIONS_SUCCEDED"
export const FETCH_CIRCONSCRIPTIONS_FAILED = "circonscription@FETCH_CIRCONSCRIPTIONS_FAILED"


export const CLEAR_CIRCONSCRIPTION = "CIRCONSCRIPTION@CLEAR_CIRCONSCRIPTION"

export const CREATE_CIRCONSCRIPTION_INIT = "CIRCONSCRIPTION@CREATE_CIRCONSCRIPTION_INIT"
export const CREATE_CIRCONSCRIPTION_SUCCEDED = "CIRCONSCRIPTION@CREATE_CIRCONSCRIPTION_SUCCEDED"
export const CREATE_CIRCONSCRIPTION_FAILED = "CIRCONSCRIPTION@CREATE_CIRCONSCRIPTION_FAILED"


export const EDIT_CIRCONSCRIPTION_INIT = "CIRCONSCRIPTION@EDIT_CIRCONSCRIPTION_INIT"
export const EDIT_CIRCONSCRIPTION_SUCCEDED = "CIRCONSCRIPTION@EDIT_CIRCONSCRIPTION_SUCCEDED"
export const EDIT_CIRCONSCRIPTION_FAILED = "CIRCONSCRIPTION@EDIT_CIRCONSCRIPTION_FAILED"

export const DELETE_CIRCONSCRIPTION_INIT = "CIRCONSCRIPTION@DELETE_CIRCONSCRIPTION_INIT"
export const DELETE_CIRCONSCRIPTION_SUCCEDED = "CIRCONSCRIPTION@DELETE_CIRCONSCRIPTION_SUCCEDED"
export const DELETE_CIRCONSCRIPTION_FAILED = "CIRCONSCRIPTION@DELETE_CIRCONSCRIPTION_FAILED"


export const DEACTIVATE_CIRCONSCRIPTION_INIT = "CIRCONSCRIPTION@DEACTIVATE_CIRCONSCRIPTION_INIT"
export const DEACTIVATE_CIRCONSCRIPTION_SUCCEDED = "CIRCONSCRIPTION@DEACTIVATE_CIRCONSCRIPTION_SUCCEDED"
export const DEACTIVATE_CIRCONSCRIPTION_FAILED = "CIRCONSCRIPTION@DEACTIVATE_CIRCONSCRIPTION_FAILED"

export const ACTIVATE_CIRCONSCRIPTION_INIT = "CIRCONSCRIPTION@ACTIVATE_CIRCONSCRIPTION_INIT"
export const ACTIVATE_CIRCONSCRIPTION_SUCCEDED = "CIRCONSCRIPTION@ACTIVATE_CIRCONSCRIPTION_SUCCEDED"
export const ACTIVATE_CIRCONSCRIPTION_FAILED = "CIRCONSCRIPTION@ACTIVATE_CIRCONSCRIPTION_FAILED"
