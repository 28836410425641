export const FETCH_GENERAL_DIRECTORS_INIT = "general@FETCH_GENERAL_DIRECTORS_INIT"
export const FETCH_GENERAL_DIRECTORS_SUCCEDED = "general@FETCH_GENERAL_DIRECTORS_SUCCEDED"
export const FETCH_GENERAL_DIRECTORS_FAILED = "general@FETCH_GENERAL_DIRECTORS_FAILED"


export const FETCH_GENERAL_DIRECTOR_INIT = "general@FETCH_GENERAL_DIRECTORS_INIT"
export const FETCH_GENERAL_DIRECTOR_SUCCEDED = "general@FETCH_GENERAL_DIRECTORS_SUCCEDED"
export const FETCH_GENERAL_DIRECTOR_FAILED = "general@FETCH_GENERAL_DIRECTORS_SUCCEDED"


export const CLEAR_GENERAL_DIRECTOR = "general@CLEAR_GENERAL_DIRECTOR"
