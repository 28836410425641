import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
import { lazy } from "react";
import { LIST } from "src/constants";
import { DIRECTOR } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";

const directorComponent = lazy(() => import("./../components/card/director"));

export const directorList = {
  path: "/structure/director",
  component: directorComponent,
  can: VaccinationManagement.module[DIRECTOR].permissions[LIST],
};

// export const circonscriptionAdd = {
//     path: "/circonscription/add",
//     component: circonscriptionAddComponent,
//     can: VaccinationManagement.module[CIRCONSCRIPTION].permissions[CREATE]
//   }

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    directorList,
  }
);
