import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
import { lazy } from "react";
import { CREATE, LIST, UPDATE, VIEW,VIEW_MENU } from "src/constants";
// import { VaccinationManagement } from "src/constants/ModulesPermissions"
import { MODULES_PERMISSIONS } from "src/constants";
import { SPECIALTIE } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";

const SpecialtieComponent = lazy(() =>
  import("../components/card/SpecialtiePage")
);
const SpecialtieNewEdit = lazy(() =>
  import("./../components/card/SpecialtieNewEdit")
);
const SpecialtieShow = lazy(() =>
  import("./../components/card/SpecialtieShow")
);

// const { SPECIALTIE } = MODULES_PERMISSIONS;

export const specialtieList = {
  path: "/repositories/specialtie",
  component: SpecialtieComponent,
  can: VaccinationManagement.module[SPECIALTIE].permissions[VIEW_MENU],
};

export const specialtieCreate = {
  path: "/add/new",
  component: SpecialtieNewEdit,

  // can: SPECIALTIE.permissions[CREATE]
  // can: SPECIALTIE.permissions[CREATE],
};

export const specialtieEdit = {
  path: "/edit/:param/specialtie",
  component: SpecialtieNewEdit,

  // can: SPECIALTIE.permissions[UPDATE]
  // can: SPECIALTIE.permissions[UPDATE],
};

export const specialtieShow = {
  path: "/specialtie/:param",
  component: SpecialtieShow,
  can: VaccinationManagement.module[SPECIALTIE].permissions[VIEW],
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    specialtieList,
    specialtieCreate,
    specialtieEdit,
    specialtieShow,
  }
);
