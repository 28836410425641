import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"
import { lazy } from "react"
import { CREATE, LIST, SEARCH, UPDATE, VIEW } from "src/constants"
import { ANTIRABIQUE, CHILDREN, OCCASIONAL, ROUTINE, SEASONAL, VACCINATION } from "src/constants/AppPermissions"
import { CitizenManagement, VaccinationManagement } from "src/constants/ModulesPermissions"
import { components } from "react-select/dist/react-select.cjs.prod"




const VaccinationFolders = lazy(() => import("./../VaccinationFolder"))
const VaccinationOccationalFolders = lazy(() => import("./../VaccinationOccationalFolder"))
const VaccinationSaisonFolders = lazy(() => import("./../VaccinationSaisonFolder"))

const VaccinationSearch = lazy(() => import("../components/card/VaccinationSearch"))
const VaccinationOccasionals = lazy(() => import("../components/card/VaccinationOccasionalSearch"))
const VaccinationNewEdit = lazy(() => import("../components/card/VaccinationNewEdit"))
const VacationOccasionalNewEdit = lazy(() => import("../components/card/OccationalVaccinationsNewEdit"))


const VaccinationHomePage = lazy(() => import("./../VaccinationHomePage"))


const VaccinationSeasonsSearch = lazy(() => import("./../components/card/VaccinationSaisonSearch"))
const VaccinationSeasonNewEdit = lazy(() => import("./../components/card/SeasonVaccinationsNewEdit"))

const VaccinationAntirabiesSearchComponent = lazy(() => import("./../components/card/VaccinationAntirabiesSearch"))
const VaccinationficheAntirabiesFolders = lazy(() => import("./../VaccinationFicheAntirabiesFolder"))
const VacationAntirabiesNewEdit = lazy(() => import("../components/card/VaccinationAntirabiesNewEdit"))
//const vaccinationAntirabiesSchema = lazy(() => import("../components/card/VaccinationAntirabiesSchemas"))

export const vaccinationCreate = {
  path: "/vaccine/routine-vaccination/new/:param",
  component: VaccinationNewEdit,
  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][ROUTINE]
}




export const vaccinationOccationalCreate = {
  path: "/vaccine/occasional-vaccination/new/:param",
  component: VacationOccasionalNewEdit,
  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][OCCASIONAL]
}



export const vaccinationSeasonCreate = {
  path: "/vaccine/season-vaccination/new/:param",
  component: VaccinationSeasonNewEdit,
  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][SEASONAL]
}


export const vaccinationOccasionals = {
  path: "/vaccine/occasional-vaccination",
  component: VaccinationOccasionals,
  exact:true,

  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][OCCASIONAL]
}

export const VaccinationSeasonSearch = {
  path: "/vaccine/season-vaccination",
  component: VaccinationSeasonsSearch,
  exact:true,

  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][SEASONAL]
}

export const vaccinationFolder = {
  path: "/vaccine/routine-vaccination/vaccinationFolder/:param([0-9]{9})",
  component: VaccinationFolders,
  can: VaccinationManagement.module[VACCINATION].permissions[VIEW][ROUTINE]
}
export const vaccinationOccationalFolder = {
  path: "/vaccine/occasional-vaccination/:param([0-9]{9})",
  component: VaccinationOccationalFolders,
  can: VaccinationManagement.module[VACCINATION].permissions[VIEW][OCCASIONAL]
}

export const VaccinationSaisonFolder = {
  path: "/vaccine/season-vaccination/:param([0-9]{9})",
  component: VaccinationSaisonFolders,
  can: VaccinationManagement.module[VACCINATION].permissions[VIEW][SEASONAL]
}


export const vaccinationList = {
  path: "/vaccine/routine-vaccination",
  component: VaccinationSearch,
  exact:true,
  can: CitizenManagement.module[CHILDREN].permissions[SEARCH]

}

export const vaccination = {
  path: "/vaccine",
  component:VaccinationHomePage,
  exact:true
}

/* Antirabies vaccination routes */
export const VaccinationAntirabiesSearch = {
  path: "/vaccine/antirabique-vaccination",
  component: VaccinationAntirabiesSearchComponent,
  exact:true,

  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][ANTIRABIQUE]
}


/* export const VaccinationAntirabiesSchemas = {
  path: "/vaccine/antirabique-vaccination/schema",
  component: vaccinationAntirabiesSchema,
  exact:true,

  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE]
}
 */

export const vaccinationAntirabiesFolder = {
  path: "/vaccine/antirabique-vaccination/:param([0-9]{9})",
  component: VaccinationficheAntirabiesFolders,
  can: VaccinationManagement.module[VACCINATION].permissions[VIEW][ANTIRABIQUE]
}

export const vaccinationAntirabiesCreate = {
  path: "/vaccine/antirabique-vaccination/new/:param",
  component: VacationAntirabiesNewEdit,
  can:  VaccinationManagement.module[VACCINATION].permissions[CREATE][ANTIRABIQUE]
}

export const vaccinationAntirabiesEdit = {
  path: "/vaccine/antirabique-vaccination/edit/:param",
  component: VacationAntirabiesNewEdit,
  can:  VaccinationManagement.module[VACCINATION].permissions[UPDATE][ANTIRABIQUE]
}
export const vaccinationAntirabiesView = {
  path: "/vaccine/antirabique-vaccination/details/:param",
  component: VacationAntirabiesNewEdit,
  can:  VaccinationManagement.module[VACCINATION].permissions[VIEW][ANTIRABIQUE]}

const path = routes.admin.path

export default combinePathRoutes({ path }, {
  vaccination,
  vaccinationList,
  vaccinationFolder,
  vaccinationOccationalFolder,
  vaccinationCreate,
  vaccinationOccasionals,
  vaccinationOccationalCreate,
  VaccinationSeasonSearch,
  VaccinationSaisonFolder,
  vaccinationSeasonCreate,
  VaccinationAntirabiesSearch,
  vaccinationAntirabiesFolder,
  vaccinationAntirabiesCreate,
  vaccinationAntirabiesEdit,
  vaccinationAntirabiesView
  //VaccinationAntirabiesSchemas
  

})
