import React, { useEffect, useState } from "react";
import { useFieldCSSClasses } from "./FieldError";
import { useFormikContext } from "formik";
import _ from "lodash";
import useHideAndShowField from "./hooks/useHideAndShowField";
import { isRLTLang } from "src/i18n";
import { FormattedError } from "../../alerts/FormattedError";
const Radio = ({
  label,
  icon = null,
  field,
  backgroundColor = "transparent",
  borderRadius = null,
  subLabel,
  grid = false,
  flex = false,
  form,
  size = 12,
  loadValue,
  options,
  required = false,
  inputGroupClassName = "form-group",
  chainedFields=[],
  inputClassName = "custom-control-input",
  onFilterChange,
  onAfterChange,
  ...props
}) => {
  const formik = useFormikContext();
  const fieldCSSClasses = useFieldCSSClasses(
    form.touched,
    form.errors,
    field.name
  );
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });
  const onChange = (event) => {
    if (!_.isEmpty(chainedFields) && _.isArray(chainedFields)){
      let arrLength = chainedFields.length
      for (let i = 0 ; i < arrLength; i++){
        form.setFieldValue(chainedFields[i],[])
        formik.setFieldValue(chainedFields[i],[])
      }
    }
    form.setFieldValue(field.name, event.target.value);
    if(_.isFunction(onAfterChange)){
      onAfterChange(event.target.value == "2" ? true : false)
    }
  };
  const [checked, setChecked] = useState();

  useEffect(() => {
    if (field.value) {
    let doc = document.getElementById(field.value + field.name) 
      if (!doc) return
      doc.checked = true;
      setChecked(true);
    } else if (_.isEmpty(field.value)) {
      const ele = document.getElementsByName(field.name);
      for (let i = 0; i < ele.length; i++) {
        ele[i].checked = false;
      }
      setChecked(false);
    }
  }, [field.value, field.name]);


  useEffect(() => {
    if (loadValue){
      formik.setFieldValue(field.name,loadValue)
    }
    return () => {
      
    }
  }, [loadValue])
  
  const dir = isRLTLang() ? "rtl" : "ltr";
  return (
    <div
      className={
        inputGroupClassName +
        conditionalShowAndHideClassName +
        ` col-lg-${size}`
      }
    >
      <div
        style={{
          backgroundColor: !_.isNull(backgroundColor) && backgroundColor,
          borderRadius: !_.isNull(borderRadius) && borderRadius,
        }}
      >
        {label && (
          <label className="custom-label-style">
            {" "}
            {label} <span>{required && "*"}</span>
          </label>
        )}
        {subLabel && (
          <div
            className="d-flex align-items-center p-4"
            style={{ gap: ".4375rem" }}
          >
            {icon && icon}
            <div className="custom-subLabel-style">{subLabel}</div>
          </div>
        )}
        {grid ? (
          <div className="pt-5 pb-5 radio-custom-grid flex-lg-row flex-column p-5">
            {options.map((option, i) => (
              <div
                key={i}
                dir={dir}
                className={` ${fieldCSSClasses}`}
                style={{ display: "flex", gap: ".375rem" }}
              >
                <input
                  disabled={option.disabled}
                  onChange={onChange}
                  name={field.name}
                  type="radio"
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  id={option.value + field.name}
                  className={inputClassName + ` ${field.name}`}
                  value={option.value}
                />
                <label htmlFor={option.value + field.name} className="">
                  {option.label}
                </label>
              </div>
            ))}{" "}
          </div>
        ) : (
          <div
            className={`d-flex gaps-48 flex-lg-row flex-column ${
              backgroundColor != "transparent" && `p-5`
            } `}
          >
            {options.map((option, i) => (
              <div
                key={i}
                dir={dir}
                className={` ${fieldCSSClasses}`}
                style={{ display: "flex", gap: ".375rem", flexWrap: "no-wrap" }}
              >
                <input
                  disabled={option.disabled}
                  onChange={onChange}
                  name={field.name}
                  type="radio"
                  id={option.value + field.name}
                  className={inputClassName + ` ${field.name}`}
                  value={option.value}
                />
                <label htmlFor={option.value + field.name} className="">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
        {checked === false && (
          <FormattedError
            message={"ERROR.CODE.VALIDATION.REQUIRED"}
            onClose={null}
          />
        )}
      </div>
    </div>
  );
};
export default Radio;
