import React from "react"
import { isEmpty, isPlainObject } from "lodash"
import { FormattedMessage } from "react-intl"
import ContentLoader from "react-content-loader"
import Typography from "@material-ui/core/Typography";

import DisplayItem from "./DisplayItem"
import {Col} from "react-bootstrap"
import CustomizedTreeView from "../../extras/TreeView"
import StyledTreeItem from "../../extras/StyledTreeItem"
import {getAttr} from "../../../../../helpers"
import useHideAndShowField from "./hooks/useHideAndShowField"
import { makeStyles } from "@material-ui/core/styles";
import { isRLTLang } from "./../../../../../i18n";


const ItemLodaer = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={15}
    viewBox="0 0 100% 15"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
  </ContentLoader>
)

const useStyles = makeStyles((theme) => ({
  
  primary: {
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    display: "inline",
    lineHeight: "1.25rem",
    color: "#737373",
    fontSize: ".875rem",
    minWidth: "max-content",
  }
}));
const NestedListOfItems = ({ field, object }) => {
  const classes = useStyles();

  const { name, label, formatter, html=false, icon=undefined, size = 12 } = field
  const value = getAttr(object, name, {})
  const nestedList = formatter(value)

  const conditionalShowAndHideClassName = useHideAndShowField({ object, ...field })

  return (
    <Col lg={size} className={conditionalShowAndHideClassName}>
            <div className="d-flex justify-content-start align-items-center gaps-6">
              {" "}
              <Typography component="span" className={classes.primary}>
                {" "}
                { label }
              </Typography>{" "}
              </div>
      <DisplayItem
        html={html}
        icon={icon}
        secondary={
          isEmpty(object) ? <ItemLodaer /> :
            <CustomizedTreeView>
              { (isPlainObject(nestedList) && !isEmpty(nestedList)) ? Object.keys(nestedList).map((key, idx) => (
                <StyledTreeItem key={idx} nodeId={key} label={key || <FormattedMessage id="GENERAL.EMPTY" />}>
                  { (nestedList[key] || []).map((value, idx) => ( <StyledTreeItem key={idx} nodeId={`${idx}`} label={value || <FormattedMessage id="GENERAL.EMPTY" />} /> )) }
                </StyledTreeItem>
              )) : <FormattedMessage id="GENERAL.EMPTY" /> }
            </CustomizedTreeView>
        }
      />
    </Col>
  )
}


export default NestedListOfItems
