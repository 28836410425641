export const FETCH_CENTER_INIT = "center@FETCH_CENTER_INIT"
export const FETCH_CENTER_SUCCEDED = "center@FETCH_CENTER_SUCCEDED"
export const FETCH_CENTER_FAILED = "center@FETCH_CENTER_FAILED"


export const FETCH_CENTERS_INIT = "center@FETCH_CENTERS_INIT"
export const FETCH_CENTERS_SUCCEDED = "center@FETCH_CENTERS_SUCCEDED"
export const FETCH_CENTERS_FAILED = "center@FETCH_CENTERS_FAILED"


export const CLEAR_CENTER = "center@CLEAR_CENTER"
