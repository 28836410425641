import React, { useRef } from 'react'
import _ from 'lodash'
import { Button, Col, Row } from 'react-bootstrap'
import { useFormikContext, FieldArray } from 'formik'

import { FastField } from "formik"
import { FORM_COMPONENT, DEFAULT_TYPE , SELECT } from "./../types/inputTypes"
import { useState,useEffect } from 'react'

const FormRepeater = (props) => {
  // const addRef = useRef()
  const { children, label, min = 0, name, fields, showDeleteButton=true, showAddButton=true, max = 50, show = true, parentName = "" ,disableFirst=0,loadOptions=[],chainedOptions=[]} = props

  const formik = useFormikContext()

  const fieldArrayName = React.useMemo(() => {
    return parentName + name 
    /*if (!_.isEmpty(nestedFields)){
      const field = nestedFields[0] || ''
      return field.name.substring(0, field.name.lastIndexOf('[]'))
    }
    return ''*/

    // eslint-disable-next-line
  }, [name])

  const arrayFieldValues = React.useMemo(() => {
    return _.get(formik.values, fieldArrayName, [])

    // eslint-disable-next-line
  }, [formik.values, fieldArrayName])
console.log('arrayfields',arrayFieldValues)
console.log('fields before',fields)
  const initialSnapshot = React.useMemo(() => {
    const snapshot = {}
    fields.forEach(field => {
      _.set(snapshot, field.name.substring(field.name.lastIndexOf('[]') + 1), _.get(field, 'initialValue', ''))
    })
    return snapshot

    // eslint-disable-next-line
  }, [fields])
  console.log('initialsnapshot',initialSnapshot)

  const [options, setOptions] = useState(new Map());

  const addToOptions = (key, value) => {
    console.log("Adding options:", key, value);

    setOptions(new Map(options.set(key, value)));
  };

  const loadOptionsValue = async (fieldName, loadFieldOptions) => {
    console.log('options',options.has(fieldName))
    if (options.has(fieldName)) {
      return;
    }
    const value = await loadFieldOptions();
    console.log('value',value)
    addToOptions(fieldName, value);
  };
  

  useEffect(() => {
    console.log("useEffect triggered");

    const loadAllOptions = async () => {
      console.log("Loading options...");

      if (!_.isEmpty(loadOptions)) {
        for (const { fieldName, loadFieldOptions } of loadOptions) {
          await loadOptionsValue(fieldName, loadFieldOptions);
        }
      }
      if (!_.isEmpty(chainedOptions)) {
        for (const { fieldName, chainedOptions } of chainedOptions) {
          const value = _.get(formik.values, fieldName);
          console.log('Field name:', fieldName, 'Value:', value);
          
          if (value !== undefined) {
            console.log('Fetching chained options for field:', fieldName);
            const options = await chainedOptions(value);
            console.log('Chained options:', options);
            addToOptions(fieldName, options);
          } else {
            console.log('Value is undefined for field:', fieldName);
          }
        }
      }
    };
  
    loadAllOptions();
  }, [loadOptions, chainedOptions, formik.values]); // Include formik.values as a dependency
  

  const renderField = ({ name, arrayName, component, ...props }) => (
    <FastField
      key={arrayName + name}
      name={arrayName + name}
      component={FORM_COMPONENT[component] || DEFAULT_TYPE}
      {...props}
    />
  );

  const renderFields = (arrayName, fields, index) => (
    <>
      {fields.map((input, i) => {
        const { validation, ...field } = input;
        const { hideOn } = field;
        let field2;

        if (!_.isEmpty(hideOn) && options.has(field.name) && field.component === SELECT) {
          field2 = { ...field, hideOn: `${arrayName}${hideOn}` , options: options.get(field.name) };
        } else if (_.isEmpty(hideOn) && options.has(field.name) && field.component === SELECT) {
          field2 = { ...field, options: options.get(field.name) };
        } else if (field.component !== SELECT && _.isEmpty(hideOn)) {
          field2 = { ...field };
        } else if (field.component !== SELECT && !_.isEmpty(hideOn)) {
          field2 = { ...field, hideOn: `${arrayName}${hideOn}` };
        } else if (field.component === SELECT && _.isEmpty(hideOn) && _.isEmpty(loadOptions)) {
          field2 = { ...field };
        } else if (field.component === SELECT && !_.isEmpty(hideOn) && _.isEmpty(loadOptions)) {
          field2 = { ...field, hideOn: `${arrayName}${hideOn}` };
        }

        return disableFirst && disableFirst > index ? (
          renderField({
            ...field2,
            disabled: true,
            arrayName,
          })
        ) : (
          renderField({
            ...field2,
            arrayName,
          })
        );
      })}
    </>
  );
console.log('fields',fields)
  return (
    <div className={!show ? 'd-none' : 'pb-3'}>
      <FieldArray
        name={fieldArrayName}
        render={arrayHelpers => {
          return (
            <div>
              { showAddButton && <Button
                disabled={_.isArray(arrayFieldValues) && arrayFieldValues.length >= max}
                // ref={addRef}
                className='btn btn-sm font-weight-bold btn-primary'
                onClick={() => _.isArray(arrayFieldValues) && arrayFieldValues.length < max && arrayHelpers.push(initialSnapshot)}
              >
                <i className='fas fa-plus' />
                {label}
              </Button>}
              {( _.has(formik.values, fieldArrayName) && _.isArray(arrayFieldValues)) && arrayFieldValues.map((__, index) => (
                <div className='mt-5 border-bottom' key={index}>
                  <Row>
                    <Col lg={ showDeleteButton ? 11 : 12}>
                      <Row className='d-flex align-items-end'>
                        { renderFields(`${fieldArrayName}.${index}.`, fields) }
                      </Row>
                      <Row>
                        <Col>
                      { !_.isEmpty(children) && React.cloneElement(children, { parentName: `${fieldArrayName}.${index}.` }) }
                        </Col></Row>
                    </Col>
                    {showDeleteButton && <Col lg='1' className='d-flex align-items-center justify-content-center'>
                      <Button
                        disabled={min > 0 ? _.isArray(arrayFieldValues) && arrayFieldValues.length === min : false}
                        className='btn btn-sm font-weight-bold btn-danger btn-icon'
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className='fas fa-trash-alt' />
                      </Button>
                    </Col> }
                  </Row>
                </div>
              ))}
            </div>
          )
        }}
      />
    </div>
  )
}
export default FormRepeater
