import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
import { lazy } from "react";
import { CREATE, LIST, UPDATE, VIEW } from "src/constants";
import { CIRCONSCRIPTION } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";

const circonscriptionComponent = lazy(() =>
  import("../components/card/Circonscription")
);
// const circonscriptionAddComponent = lazy(() =>
//   import("./../components/card/circonscriptionAdd")
// );

export const circonscriptionList = {
  path: "/structure/circonscription",
  component: circonscriptionComponent,
  can: VaccinationManagement.module[CIRCONSCRIPTION].permissions[LIST],
};

export const circonscription = {
  path: "/structure",
  exact: true,
  // can: VaccinationManagement.module[CIRCONSCRIPTION].permissions[CREATE]
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    circonscriptionList,
    circonscription,
    // circonscriptionAdd,
  }
);
